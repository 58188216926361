@mixin media-breakpoint-hd-desktop {
  @media (min-width: 1500px) {
    @content;
  }
}

@mixin media-breakpoint-med-desktop {
  @media screen and (max-width: 1399px) and (min-width: 1200px) {
    @content;
  }
}
@mixin media-breakpoint-mobile-land {
  @media (max-width: 768px) {
    @content;
  }
}
@mixin media-breakpoint-tab-port {
  @media screen and (max-width: 991px) and (min-width: 768px) {
    @content;
  }
}
@mixin media-breakpoint-tab-land {
  @media screen and (max-width: 1199px) and (min-width: 992px) {
    @content;
  }
}
@mixin media-breakpoint-mobile-port {
  @media (max-width: 480px) {
    @content;
  }
}

@mixin common-button {
  font-family: 'Lato', sans-serif;
  border-radius: 4px;
  width: 100%;
  height: 48px;
  padding: 0px 10px;
  line-height: 22px;
}

@mixin viewDetailPages($font_size, $font-weight, $line-height: 24px) {
  font-weight: $font-weight;
  font-size: $font_size;
  line-height: 24px;
  color: $black-50;
}
