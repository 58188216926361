@import './variables.scss';
@import './mixins.scss';

.title {
  color: $black-50;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
}

.page-title {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #505050;
  padding-left: 10px;
}

.main-content {
  font-weight: 400;
  font-size: 24px;
  line-height: 44px;
  text-align: center;
  color: $black;
}

.inputLabel {
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: $black-11;
  padding-bottom: 10px;
}

.wrapper {
  padding: 35px 10px;
}

.activestatus {
  background: rgba(76, 175, 80, 0.55);
  border-radius: 4px;
  padding: 5px 17px;
}

.activepublishstatus {
  background: rgba(76, 175, 80, 0.55);
  border-radius: 4px;
  padding: 5px 20px;
}

.inactivestatus {
  background: rgba(255, 79, 91, 0.49);
  border-radius: 4px;
  padding: 5px 10px;
}

.page-shadow {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  padding: 20px 30px;
  margin: 20px 0px;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

//Hide arrow in input box if type=number

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

//React select input size over ride
.css-g1d714-ValueContainer {
  min-height: 50px !important;
}

.css-yk16xz-control {
  min-height: 50px !important;
}

.css-1pahdxg-control {
  box-shadow: 0px 0px 6px rgba(255, 140, 109, 0.6) !important;
  min-height: 50px !important;
  border: 1px solid $secondary-color-pink !important;
}

.css-1okebmr-indicatorSeparator {
  background-color: transparent !important;
}

//Custom switch over ride

#custom-switch {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%235F7584'/%3e%3c/svg%3e");
  background-color: $white;
  border: 2px solid $gray-84;
}

#custom-switch:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
  background-color: $green;
  border: 0px;
}

//quill custom css

.quill {
  .ql-toolbar {
    background: #f5f5f5;
    border: 0.5px solid #606060;
  }

  .ql-container {
    min-height: 50vh;
  }
}

.primary-text-color {
  color: $primary-color-blue;
}
//action dropdown override

.dropdown-toggle::after {
  display: none;
}

.imagepreview {
  width: 100px;
  height: 100px;
  border: 1px solid #c4c4c4;
}

.imagepreviewModal {
  width: 175px;
  height: 175px;
  border: 1px solid #c4c4c4;
}

.bannerpreviewModal {
  width: 300px;
  height: 200px;
  border: 1px solid #c4c4c4;
}

//View Details pages common title,values

.view-page-title {
  @include viewDetailPages(20px, 400);
}

.view-page-subtitle {
  @include viewDetailPages(20px, 700);
}

.view-page-paymentvalue {
  @include viewDetailPages(16px, 400, 22px);
}

.view-page-shipping {
  @include viewDetailPages(16px, 700);
}

//coupon add edit page
.couponcontainer {
  background: #f9f9f9;
  border: 0.25px solid #c0c0c0;
  padding: 10px;
}

.selectedvalue {
  background: rgba(0, 76, 107, 0.19);
  border-radius: 4px;
  margin: 5px 10px;
  padding: 5px 10px;
  display: inline-block;
}

//product image in list

.productContainer {
  background: #fbfbff;
  border: 0.5px solid #b3b3b3;
  border-radius: 2px;
  padding: 5px 10px;
  margin-right: 10px;
}

.productImage {
  width: 29px;
  height: 46px;
}

.outOfStockText {
  color: red;
  display: inline;
  margin-left: 20px;
  font-size: 12px;
}

.donwload {
  border: 1px solid #000000;
  border-radius: 4px;
  background-color: transparent;
  padding: 10px;
}

//product
.pt-30 {
  padding-top: 30px;
}

.pt-53 {
  padding-top: 53px;
}

.primaryTextline {
  font-weight: normal;
  font-family: 'Poppins', sans-serif;
  font-size: 20px;
  line-height: 30px;
}

.chartCard {
  background: #ffffff;
  box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.12);
  border-radius: 5px;

  .headerBox {
    padding: 20px;
    border-bottom: 0.5px solid #d5d5d5;

    .month {
      font-family: 'Lato', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      color: #000000;
      margin-bottom: 0px;
      margin-left: 10px;
    }

    p {
      font-family: 'Lato', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 22px;
      color: #000000;
      margin-bottom: 0px;
    }
  }

  .productTabBox {
    display: flex;
    border: 0.6px solid #c2c2c2;
    border-radius: 2px;
    height: 40px;
    align-items: center;
    width: 300px;

    .activeTab {
      background: #ff8c6d;
      border-radius: 2px;
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      color: #000000;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    .inActiveTab {
      border-radius: 2px;
      cursor: pointer;

      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      color: #707070;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .chartDropDown {
    border: none;
    outline: none;
  }
}

.sectionBorderStyle {
  background: #ffffff;
  border: 1px solid #bbbbbb;
  border-radius: 6px;
  padding: 3rem 0px;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.paymentSuccessLogo {
  width: 175px;
  margin: auto;

  img {
    width: 100%;
    object-fit: contain;
  }
}

.actionicon-15 {
  margin-right: 15px;
  width: 20px;
}
.addBtn {
  margin-left: -40px;
}

.actionDropDown {
  position: unset !important;
}

.ant-upload-list-item {
  border-radius: 50% !important;
  padding: 0px !important;
  height: 300px;
  width: 100px;
  border-color: transparent !important;
}

.ant-upload-list-item::before {
  border-radius: 50% !important;
  width: calc(100% - 0px) !important;
  height: calc(100% - 0px) !important;
}

.ant-upload-list-item-thumbnail {
  border-radius: 50%;
}

.ant-upload {
  border-radius: 50% !important;
  // margin-bottom: 110px !important;
}

.imgTop {
  margin-right: 250px;
}

//banner Image
.bannerContainer {
  background: #fbfbff;
  border: 0.5px solid #b3b3b3;
  border-radius: 10px;
  padding: 5px 5px;
}

.bannerImage {
  width: 150px;
  height: 50px;
}

.viewbanner {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.viewbanner img {
  display: block;
  max-width: 100%;
  height: auto;
}

@media only screen and (max-width: 425px) {
  .imgTop {
    margin-right: 200px;
  }
}

.ant-modal .ant-modal-close-x {
  line-height: 12px !important;
}
