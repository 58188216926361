$primary-color-blue: #004c6b;
$secondary-color-pink: #ff8c6d;
$blue-5c: #10375c;
$black: #000000;
$black-11: #0d0e11;
$black-33: #333333;
$black-50: #505050;
$white: #ffffff;
$error: #d42211;
$light-pink: #fff7f2;
$gray-c4: #c4c4c4;
$gray-66: #666666;
$gray-67: #676767;
$gray-6c: #6c6c6c;
$gray-bb: #bbbbbb;
$gray-bd: #bdbdbd;
$gray-dc: #dcdcdc;
$gray-67: #c7c7c7;
$gray-fb: #fbfbfb;
$gray-84: #5f7584;
$gray-7e: #7e7e7e;
$gray-e8: #e8e8e8;
$green: #27962b;
$violet: #29296d;
$full-height: 100vh;
$yellowish-orange: #fec97b;
$light-grey-green: #9cdf9f;
$gainsboro: #dedede;
$seapink: #eca0a0;
$ginger: #a76600;
$ironside-grey: #666666;
$japanese-laurel: #1a6c1c;
$sanguine-brown: #8b3434;
$alto-approx: #d7d7d7;
