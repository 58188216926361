.authbg {
  width: 100%;
}

.container {
  width: 65%;
  margin: 0 auto;
}

.authbg {
  background: url("../assets/images/authlayoutbg.png");
  height: 100vh;
  background-size: cover;
}
