@import 'themes/scss/variables.scss';
@import 'themes/scss/mixins.scss';

.commonButton {
  @include common-button;
  color: $white;
  background: $secondary-color-pink;
  border: none;
  font-size: 18px;
  font-weight: 700;
  transition: 0.2s all;
  height: 40px;
}
.primaryButton {
  @include common-button;
  color: $white;
  background: $primary-color-blue;
  border: none;
  font-size: 16px;
  font-weight: 700;
  transition: 0.2s all;
  height: 40px;
}
.bookAppointmentButton {
  @include common-button;
  color: $black;
  background: $secondary-color-pink;
  border: none;
  font-size: 16px;
  font-weight: 700;
  transition: 0.2s all;
  border-radius: 7px;
}

.cancelButton {
  @include common-button;
  color: $blue-5c;
  background: transparent;
  border: none;
  font-size: 16px;
  font-weight: 700;
  transition: 0.2s all;
  height: 40px;
}

.button-outline {
  @include common-button;
  font-size: 16px;
  font-weight: 700;
  color: $primary-color-blue;
  background: $white;
  border: 1px solid $primary-color-blue;
  transition: 0.2s all;
  height: 40px;
}

.commonButton:active,
.button-outline:active,
.primaryButton:active,
.cancelButton:active {
  transform: scale(0.98);
}

button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.transparent {
  background: transparent;
  outline: none;
  border: none;
  color: $primary-color-blue;
  font-weight: 600;
  text-decoration: underline;
  font-size: 16px;
  line-height: 19px;
  height: 40px;
}

@include media-breakpoint-mobile-land {
  .commonButton,
  .button-outline {
    font-size: 16px;
    height: 45px;
  }
}
